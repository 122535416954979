<template>
	<div class="container" :class="{'KnaTake': !KnaTake}">
		<div class="package-header">
			<div class="icon-return" @click="$router.back()"></div>
			我的背包<span>（共有：{{ total }}件）</span>
			<div class="sort" @click="sortChange">
				{{ order == '' ? '默认升序' : order == 'asc' ? '价值升序' : '价值降序'}}
				<img v-show="order == 'asc'" src="~@/assets/img/icon-sort.png" alt="">
				<img v-show="order == 'desc'" class="down" src="~@/assets/img/icon-sort.png" alt="">
			</div>
		</div>
		<div class="sort-list">
			<div class="item" :class="{'active': status==0}" @click="KnaTake=false;status=0;GetStorage();">道具背包</div>
			<div class="item" :class="{'active': status==4}" @click="KnaTake=true;status=4;GetStorage();">申请发货</div>
			<div class="item" :class="{'active': status==5}" @click="KnaTake=true;status=5;GetStorage();">正在发货</div>
			<div class="item" :class="{'active': status==1}" @click="KnaTake=true;status=1;GetStorage();">提货完成</div>
		</div>
		<div class="package-list">
			<div class="item" :class="{'selected': item.Select}" v-for="(item,index) in StorageList" :key="item.id" @click="Select(item)">
				<div class="alias" v-if="item.dura_alias&&item.dura_alias!='无'">{{ item.dura_alias }}</div>
				<div class="img-con">
					<img :src="item.cover" alt="">
				</div>
				<div class="name">{{ item.name }}</div>
				<div class="price">
					<div class="coins"></div>
					{{ item.bean }}
				</div>
				<div class="take-type" v-if="KnaTake" :class="[status==1?'green':'', status==4?'red':'']">{{ TakeOption[status] }}</div>
			</div>
		</div>
		<div class="page-con">
			<el-pagination background layout="prev, pager, next" :total="total" :page-size="per_page" @current-change="handleCurrentChange" :pager-count='5' prev-text="上一页" next-text="下一页" small></el-pagination>
		</div>
		<div class="package-bottom-con" v-show="!KnaTake">
			<div class="total">
				选<span>{{ selectNumber }}</span>件, 总价值<span>{{ selectBean }}</span>
			</div>
			<div class="btn-con">
				<div class="btn" @click="SelectAll">全选</div>
				<div class="btn" @click="PostExtract">取回</div>
				<!-- <div class="btn" @click="PostCash">出售</div> -->
			</div>
		</div>
		<van-overlay :show="openBack">
			<div class="wrapper">
				<div class="back-con">
					<div class="back-header">取回道具<div class="close" @click="openBack=false"></div></div>
					
					<div class="back-list-con">
						<div class="scroll-con">
							<el-scrollbar style="height:100%;">
								<div class="back-list">
									<div class="item" v-for="(item,index) in StorageList" :key="index" v-show="item.Select">
										<div class="img-con">
											<img :src="item.cover" alt="">
										</div>
										<div class="name">{{ item.name }}</div>
										<div class="price">
											<div class="coins"></div>{{ item.bean }}
										</div>
									</div>
								</div>
							</el-scrollbar>
						</div>
					</div>
					<div class="btn-con">
						<div class="btn" @click="openBack=false">取消</div>
						<div class="btn active" @click="PostExtractSure">确认取回</div>
					</div>
				</div>
			</div>
		</van-overlay>
	</div>
</template>

<script>
	import{
		Storage,
		Cash,
		Extract,
		Give,
		PersonalInfo
	} from '@/network/api.js'
	import {
		mapMutations
	} from 'vuex'
	export default {
		name: 'Warehouse',
		data() {
			return {
				TakeValue: 4, //提货下拉绑定
				TakeOption: {
					'4': '申请提货',
					'5': '正在发货',
					'6': '等待收货',
					'1': '提货完成'
				},
				// TakeOption: [{
				// 		text: '申请提货',
				// 		value: 4
				// 	},
				// 	{
				// 		text: '正在发货',
				// 		value: 5
				// 	},
				// 	{
				// 		text: '等待收货',
				// 		value: 6
				// 	},
				// 	{
				// 		text: '提货完成',
				// 		value: 1
				// 	}
				// ],//提货下拉列表
				StorageList:[],//背包列表
				total:0,//背包总数据
				per_page:0,//一页数量
				page:'1',//背包列表页码
				status:'0',//提货物品状态type
				// 0 => '待操作',   默认状态
				// 1 => '领取成功',  已领取到Steam账户
				// 4 => '申请提货', 申请提货中，待平台发货的状态。
				// 5 => '正在发货', 平台已购买等待ZBT发货
				// 6 => '等待收货' ZBT已发货待领取
				
				Select_All:false,//是否全选状态
				
				KnaTake:false,//背包/提货
				
				GiveShow:false,//赠送遮罩层
				GiveImg:null,//赠送物品图片
				GiveBack:null,//赠送物品背景
				GiveId:'',//赠送物品Id
				Invitation_code:'',//赠送所需邀请码
				bean:0,
				selectumber: 0,
				order: '',
				openBack: false
			}
		},
		computed:{
			selectNumber(){
				return this.StorageList.filter(item=>item.Select).length
			},
			selectBean(){
				let beans = this.StorageList.filter(item=>item.Select).map(item=>parseFloat(item.bean))
				let bean = 0
				beans.map(item=>{
					bean+=item
				})
				return bean.toFixed(2)
			}
		},
		created() {
			if(this.$route.query.KnaTake){
				this.KnaTake = true
				this.TakeValue = this.$route.query.TakeValue
				this.status = this.$route.query.TakeValue
			}
			this.GetStorage()
		},
		watch:{
			TakeValue(v){
				console.log(v)
				this.status = String(v)
				this.page = '1'
				this.GetStorage()
			},
			$route(){
				if(this.$route.query.KnaTake){
					this.KnaTake = true
					this.TakeValue = this.$route.query.TakeValue
					this.status = this.$route.query.TakeValue
				}else{
					this.KnaTake = false
					this.status = 0
				}
				this.GetStorage()
			}
		},
		methods:{
			sortChange(){
				if(this.order == ''){
					this.order = 'asc'
				}else if(this.order == 'asc'){
					this.order = 'desc'
				}else{
					this.order = ''
				}
				this.GetStorage()
			},
			//背包列表
			GetStorage(){
				this.StorageList = []
				let params = {
					page: this.page,
					status: this.status,
					order_by_field: 'bean'
				}
				if(this.order){
					params.order = this.order
				}
				Storage(params).then((res) => {
					// console.log(res.data.data)
					this.StorageList = res.data.data.data
					this.total = res.data.data.total
					this.per_page = res.data.data.per_page
				})
			},
			
			//背包/提货列表切换
			ChangeKnaTake(v){
				// console.log(v)
				if(v == '0'){
					this.KnaTake = false
					this.status = '0'
				}else{
					this.KnaTake = true
					this.status = '4'
					this.TakeValue = 4
				}
				this.page = '1'
				this.GetStorage()
			},
			
			//背包列表分页
			handleCurrentChange(val) {
				// console.log(val)
				this.page = val
				this.GetStorage()
			},
			
			//单选
			Select(item){
				if (item.Select) {
					this.bean -= (item.bean*1)
					item.Select = false
				} else {
					this.bean += (item.bean*1)
					this.$set(item,'Select',true)
				}
			},
			
			//全选
			SelectAll() {
				if (this.Select_All == false) {
					this.bean = 0
					this.Select_All = true
					this.StorageList.map(item=>{
						this.$set(item,'Select',true)
						this.bean += (item.bean)
					})
				} else {
					this.StorageList.map(item=>{
						this.$set(item,'Select',false)
						this.bean += (item.bean)
					})
					this.bean = 0
					this.Select_All = false
				}
			},
			
			//兑换金币(回收)
			PostCash(){
				let arr = this.StorageList.filter((item) => {
					return item.Select == true
				})
				if(arr.length==0){
					return ;
				}
				this.$confirm('是否确定回收?', '提示', {
				  confirmButtonText: '确定',
				  cancelButtonText: '取消',
				  type: 'warning',
					center: true
				}).then(() => {
				  // let arr = this.StorageList.filter((item) => {
				  // 	return item.Select == true
				  // })
				  let data = []
				  for (let i = 0; i < arr.length; i++) {
				  	data.push({
				  		"id": arr[i].id
				  	})
				  }
				  Cash(JSON.stringify(data)).then((res) => {
				  	// console.log(res)
				  	this.RefreshData(res.data.message)
				  })
				}).catch(() => {
				       
				});
				
			},
			
			//物品提取(提货)
			PostExtract(){
				let arr = this.StorageList.filter((item) => {
					return item.Select == true
				})
				if(arr.length==0){
					return ;
				}
				this.openBack = true
			},
			PostExtractSure(){
				let arr = this.StorageList.filter((item) => {
					return item.Select == true
				})
				// this.$confirm('是否确定提货?', '提示', {
				//   confirmButtonText: '确定',
				//   cancelButtonText: '取消',
				//   type: 'warning',
				// 	center: true
				// }).then(() => {
				//   let data = []
				//   for (let i = 0; i < arr.length; i++) {
				//   	data.push({
				//   		"id": arr[i].id
				//   	})
				//   }
				//   Extract(JSON.stringify(data)).then((res) => {
				//   	this.RefreshData(res.data.message)
				//   })
				// }).catch(() => {
				       
				// });
				let data = []
				for (let i = 0; i < arr.length; i++) {
					data.push({
						"id": arr[i].id
					})
				}
				Extract(JSON.stringify(data)).then((res) => {
					this.RefreshData(res.data.message)
				})
			},
			
			// 打开赠送遮罩层
			PostGiveShow(item){
				// console.log(item)
				this.GiveImg = item.cover
				this.GiveId = item.id
				this.GiveBack = item.lv_bg_image
				this.GiveShow = true
			},
			
			// 赠送
			PostGive() {
				Give(this.GiveId, this.Invitation_code).then((res) => {
					this.RefreshData(res.data.message)
					this.GiveShow = false
				})
			},
			
			//个人信息
			GetPersonalInfo() {
				PersonalInfo().then((res) => {
					this.PostUser(res.data.message)
				})
			},
						
			//刷新数据
			RefreshData(message){
				this.$notify({
					type: 'success',
					message: message
				})
				this.GetStorage()
				this.GetPersonalInfo()
				for (let i = 0; i < this.StorageList.length; i++) {
					delete this.StorageList[i].Select
					this.$refs.Item[i].style.border = "1px solid transparent"
				}
				this.Select_All = false
				
			},
			
			...mapMutations([
				'PostUser'
			])
		},
		
		
	}
</script>

<style lang="scss">
	@import "@/assets/css/base.scss";
	.container{
		&.KnaTake{
			padding-bottom: 80px;
		}
	}
	.package-header{
		@include HScenter;
		background: url(~@/assets/img/rule-header.png);
		background-size: 100% 100%;
		height: 40px;
		padding: 0 12px;
		font-size: 12px;
		position: relative;
		.icon-return{
			position: absolute;
			top: 50%;
			left: 14px;
			width: 24px;
			height: 24px;
			background-size: 100% 100%;
			background-image: url(~@/assets/img/icon-return.png);
			cursor: pointer;
			transform: translateY(-50%);
		}
		span{
			font-size: 12px;
			color: #FFB800;
		}
		.sort{
			position: absolute;
			right: 14px;
			top: 50%;
			transform: translateY(-50%);
			@include Scenter;
			font-size: 12px;
			filter: grayscale(1);
			cursor: pointer;
			margin-left: auto;
			color: #FFB800;
			img{
				width: 12px;
				height: 12px;
				margin-left: 4px;
				&.down{
					transform: rotateZ(180deg);
				}
			}
		}
	}
	.package-list{
		width: 346px;
		display: flex;
		justify-content: space-between;
		row-gap: 6px;
		flex-wrap: wrap;
		margin: 12px auto;
		.item{
			width: 170px;
			position: relative;
			@include Scenter;
			flex-direction: column;
			padding: 20px;
			border: 1px solid #3C3C4B;
			background: #1D1D28;
			cursor: pointer;
			&.selected{
				border-color: #FFB800;
			}
			.alias{
				position: absolute;
				right: 4px;
				top: 4px;
				height: 20px;
				line-height: 20px;
				padding-right: 4px;
				background: #FFB800;
				color: #000;
				font-size: 12px;
				&::before{
					content: '';
					position: absolute;
					left: -19px;
					top: 0;
					border-style: solid;
					border-width: 10px;
					border-color: #FFB800 #FFB800 transparent transparent;
				}
			}
			.img-con{
				width: 112px;
				height: 64px;
			}
			.name{
				max-width: 106px;
				@include wordhide;
				margin: 12px 0;
			}
			.price{
				@include Scenter;
				font-size: 15px;
				font-weight: bold;
				.coins{
					width: 20px;
					height: 20px;
					margin-right: 8px;
				}
			}
			.take-type{
				height: 38px;
				width: 147px;
				@include HScenter;
				font-size: 15px;
				color: #FFB800;
				margin-top: 12px;
				background: #262630;
				&.green{
					color: #26D54D;
				}
				&.red{
					color: #FF1A51;
				}
			}
		}
	}
	.page-con{
		height: 52px;
		background: #232330;
		@include HScenter;
	}
	.package-bottom-con{
		height: 120px;
		position: fixed;
		z-index: 2;
		bottom: 0;
		left: 50%;
		transform: translateX(-50%);
		width: 375px;
		background: #15161B;
		border-top: 1px solid #616161;
		.total{
			line-height: 38px;
			height: 38px;
			margin-bottom: 14px;
			text-align: center;
			background: linear-gradient(180deg, #232330 0%, #2E2E3B 100%);
			span{
				color: #FFB800;
			}
		}
		.btn-con{
			display: flex;
			justify-content: center;
			gap: 8px;
			cursor: pointer;
			.btn{
				width: 112px;
				height: 42px;
				@include HScenter;
				background: linear-gradient(180deg, #232330 0%, #2E2E3B 100%);
				font-size: 12px;
			}
		}
	}
	.sort-list{
		display: flex;
		justify-content: space-between;
		width: 322px;
		margin: 12px auto 0;
		.item{
			cursor: pointer;
			@include Scenter;
			color: #FFB800;
			filter: grayscale(1);
			position: relative;
			&.active{
				filter: grayscale(0);
				&::before{
					content: '';
					position: absolute;
					left: 50%;
					bottom: -6px;
					height: 2px;
					width: 50%;
					transform: translateX(-50%);
					background: #FFB800;
				}
			}
			img{
				width: 12px;
				height: 12px;
				margin-left: 4px;
				&.down{
					transform: rotateZ(180deg);
				}
			}
		}
	}
	.back-con{
		padding: 12px 0 0;
		width: 371px;
		height: 376px;
		background-size: 100% 100%;
		background-image: url(~@/assets/img/back-bg.png);
		.back-header{
			position: relative;
			height: 42px;
			@include HScenter;
			.close{
				position: absolute;
				right: 30px;
				top: 9px;
				width: 24px;
				height: 24px;
				background-size: 100% 100%;
				background-image: url(~@/assets/img/rule-close.png);
				cursor: pointer;
			}
		}
		
		.back-list-con{
			width: 314px;
			height: 213px;
			margin: 10px auto 28px;
			.scroll-con{
				height: 100%;
				.el-scrollbar__wrap{
					overflow-x: hidden;
				}
			}
			.back-list{
				display: flex;
				flex-wrap: wrap;
				gap: 13px;
				.item{
					width: 96px;
					height: 101px;
					.img-con{
						width: 90px;
						height: 50px;
					}
					.name{
						color: #bbb;
						@include wordhide;
						width: 100%;
						text-align: center;
						margin: 5px 0;
					}
					.price{
						.coins{

						}
					}
				}
			}
		}
		.btn-con{
			@include Hcenter;
			gap: 18px;
			.btn{
				width: 124px;
				height: 36px;
				background-size: 100% 100%;
				background-image: url(~@/assets/img/back-btn.png);
				@include HScenter;
				cursor: pointer;
				&.active{
					background-image: url(~@/assets/img/back-btn-active.png);
					text-shadow: 0px 2px 4px #3A6EB5;
				}
			}
		}
	}
</style>